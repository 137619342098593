import React from 'react'
import SingleEvent from './SingleEvent'

const Event = () => {
  return (
    <div className=' '>
      <SingleEvent/>
    </div>
  )
}

export default Event
