import React from 'react'
import BackgroundImageSlider from '../BackgroundImageSlider/BackgroundImageSlider'
import './Timeline.css'
import bornImg from '../../img/30.jpg';
import { Link } from 'react-router-dom';
const Anchor = () => {
  return (
    <div className="flex pt-[90px]  h-full" id="ancor">
      <div className="w-[50%] time-bac xl:block hidden mt-4 background-ancor ">

     
       





      </div>
      <div className="xl:w-[50%] time-bac w-full" >
      <div className='time-text mx-10 py-16'>
      <h1 className='xl:text-xl text-xl'>  <span className="border-b-4 border-green-500  pb-3 w-24 font-bold">Wife:The Anchor</span>
</h1>


<div className='mt-16 '>
    
   <div className='mt-1'>
   
    <p className='my-4 text-justify text-lg font-home-about'>Much is not known of the lady who so young joined her husband in 1946 in an unknown journey – the journey which will eventually lead them to the commitment to organise a force to be reckoned with l Aganinst the wishes of her parents and relaives she embarked on to the journey to an unknown frontier. From her diary one can understand the commitment and elation to be with a person who was embarking in a very difficult mission. In the sojourn she had the chance of seeing the struggle of individual soldier and all that went to convince a hoard of soldiers who had bet their live and future in the hands of an individual. Begum Ghani’s confidence in her husband was unflinching. She was full of conviction that what ever he undertook he never failed. She would talk to us about the way she left Dhaka way back in 1946 from the house of Mr HH Nomani. She was daughter to the family and did not want spare her to join her husband. 
    </p>
   </div>
    
    <div className='py-4'>
        <Link to="/ancor" className='bg-blue-950 text-sm text-white uppercase rounded py-3 px-4'>Read more</Link>
    </div>
</div>

      
    </div>
      
      </div>
    </div>
  )
}

export default Anchor
