import React from "react";

const PhotoMenu = () => {
  return (
    <div className="xl:fixed top-0 z-50 max-w-screen w-full xl:block hidden">
      <ul className="flex justify-start gap-3 bg-gray-900 h-20 xl:py-4 px-2 ">
        <li className="px-2 py-1 text-black text-xl hover:text-yellow-500">
          <a href="#photo">
            <span className="inline-flex items-center justify-center rounded-full bg-black py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10">
              Major Ghani
            </span>
          </a>
        </li>
        <li className="px-2 py-1 text-black text-xl hover:text-yellow-500">
          <a href="#demo"> <span className="inline-flex items-center justify-center rounded-full bg-black py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10">
           Others
            </span></a>
        </li>
       
      </ul>
    </div>
  );
};

export default PhotoMenu;
