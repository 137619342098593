import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import majore1 from '../../img/117.jpeg';
import majore3 from '../../img/75.jpg';
import majore4 from '../../img/79.jpg';
import "./Bacground.css"; // Make sure to import your CSS file
import HomeCard from '../Homepage/HomeCard';
import HomeAbout from '../Homepage/HomeAbout';

const BackgroundImageSlider = () => {
  return (
    <div className="slider-container pt-12 lg:pt-1 min-h-screen">
      <Carousel
        autoPlay={true}
        interval={5000}
        infiniteLoop={true}
        showStatus={true}
        showThumbs={true}
      >
        <div className=' h-full flex items-center justify-center'>
          <div className="w-full  h-full min-h-screen rounded-lg shadow-2xl overflow-hidden relative">
            <img className="absolute inset-0 h-full w-full or" src={majore1} alt="Image 1" />
            <div className="absolute inset-0 bg-black bg-opacity-25"></div>
           
            <div className="flex h-full items-center justify-center relative">
              
            <div>
              <p className='lg:text-3xl py-2 text-lg text-white flex justify-center'> The Legacy of  Warrior</p>
            <HomeAbout/>
            </div>
            </div>
          </div>
        </div>
        <div className='h-full flex items-center justify-center'>
          <div className="w-full min-h-screen h-full rounded-lg shadow-2xl overflow-hidden relative">
            <img className="absolute inset-0 h-full w-full " src={majore4} alt="Image 2" />
            <div className="absolute inset-0  bg-black bg-opacity-25"></div>
            <div className="flex h-full items-center justify-center relative">
            <div>
              <p className='lg:text-3xl py-3 text-lg flex text-white justify-center'>Founder of East Bengal Reigiment</p>
            <HomeAbout/>
            </div>
            </div>
          </div>
        </div>
        <div className='h-full min-h-screen flex items-center justify-center'>
          <div className="w-full h-full rounded-lg shadow-2xl overflow-hidden relative">
            <img className="absolute inset-0 h-full w-full " src={majore3} alt="Image 3" />
            <div className="absolute inset-0  bg-black bg-opacity-25"></div>
            <div className="flex flex-wrap h-full items-center justify-center relative">
            <div>
              <p className='lg:text-3xl py-3 text-white text-lg flex justify-center'> Architect of Nation Building</p>
            <HomeAbout/>
            </div>
            </div>
          </div>
        </div>
        {/* Add more images as needed */}
      </Carousel>
    </div>
  );
};

export default BackgroundImageSlider;
