import { Link } from 'react-router-dom';
import { FaBars, FaArrowLeft } from 'react-icons/fa';


const Topbar = (props) => {
  return (
    <header className="sticky lg:hidden top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
        <div className="items-center gap-2 sm:gap-4 lg:hidden">
          {/* <!-- Hamburger Toggle BTN --> */}
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            className="z-99 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
          >
           <FaBars/>
          </button>
        </div>

      

        <div className="flex items-center ">
        <div className="flex items-center  mr-6 lg:hidden">
          {/* <!-- Hamburger Toggle BTN --> */}
         <p>Major Ghani</p>
          {/* <!-- Hamburger Toggle BTN --> */}

         
        </div>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
