import React from 'react'
import BackgroundImageSlider from '../BackgroundImageSlider/BackgroundImageSlider'

import './Timeline.css'

const Political = () => {
  return (
    <div className="flex pt-[90px] " id="political">
      <div className="w-[50%] time-bac xl:block hidden background-political">
       





      </div>
      <div className="xl:w-[50%] time-bac w-full">
      <div className='time-text mx-10 py-16'>
<h1 className='xl:text-xl text-xl'>
  <span className="border-b-4 border-green-500  pb-3 w-44 font-bold">Political Career</span>
</h1>


<div className='mt-16 '>
    
   <div className='mt-1 '>
 
    <p className='my-4 text-justify text-lg font-home-about'>
    Major A Ghani was a multi-dimensional leader and a statesman who did not give in to pressure as he was above party politics. He was a rebel in uniform because he would not tolerate any instruction that went against the interest of the people. 
Events led to such a pass that he was denied a commission in the Pakistan Army, yet he had to work on the perimeter of the regiment and recruit the best young men for the regiment. It is not generally known, or question not asked why such a war veteran who organised a fighting unit fell from the favour of the administration and had to leave the army.  Understandably, if it was asked during the Pakistan days. The real reason lay in his vehement protest on the issue of speaking either Bengali or Urdu.  More on this will be covered later in details. he earned the wreath of the hierarchy. He finally was so ill that and had to be medically invalided out of army after a span of only 5 years of active service since 1948. 
 

    </p>
   </div>
    
    <div>
        <p></p>
    </div>
</div>

      
    </div>
      
      </div>
    </div>
  )
}

export default Political
