import React from 'react'
import BackgroundImageSlider from '../BackgroundImageSlider/BackgroundImageSlider'

const Death = () => {
  return (
    <div className="flex pt-[90px] lg:h-[100vh] h-full" id="death">
      <div className="w-[50%] time-bac xl:block hidden background-death">
       





      </div>
      <div className="xl:w-[50%] time-bac w-full" >
      <div className='time-text mx-10 py-16'>
      <h1 className='xl:text-xl text-xl'>  <span className="border-b-4 border-green-500  pb-3 w-24 font-bold">Death</span>
</h1>


<div className='mt-16 '>
    
   <div className='mt-1'>
  
    <p className='my-4 text-justify text-lg font-home-about'>
      
    In 1957, Major Ghani from East Pakistan, despite initial cancellation, attended the Ex-Soldiers' anniversary in Berlin as Pakistan's representative. He delivered a memorable speech before suffering a fatal heart attack on November 11. News of his death brought profound grief to Comilla, where he was widely mourned. Arranging his body's return faced financial challenges; the government initially refused to cover the Rs 10,000 expense, but Lt. Col. M.A.G. Osmany's insistence led to the Defense Department and East Bengal Regiment offering to pay, eventually reimbursed by the government. Maj Ghani was buried with full military honors in Mainamati Cantonment, Comilla. After his death, his widow, Mrs. Asiya Ghani, faced financial difficulties, managing the family with a small pension and income from handicrafts. She bought a small house in Comilla, where she raised their five children, who eventually became successful
    </p>
   </div>
    
    <div>
        <p></p>
    </div>
</div>

      
    </div>
      
      </div>
    </div>
  )
}

export default Death
