import React from 'react'

import './media.css'
import MediaCard from './MediaCard'

const Media = () => {
  return (
    <div className='recog-bac recog-text'>
      <MediaCard/>
    </div>
  )
}

export default Media
