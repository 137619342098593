import React from 'react';
import './Homepage.css';
import HomeCard from './HomeCard';

const HomeAbout = () => {
  return (
    <div className="xl:mx-10 mx-4 xl:py-4 py-4 flex">
      <HomeCard />
    </div>
  );
};

export default HomeAbout;
