import React from 'react';
import { Link } from 'react-router-dom';

const SpeachesCard = ({ sl, title, desc, onClick }) => {
  return (
    <div className=" flex justify-center items-center">
      <Link to={`/speeches/${sl}`} onClick={onClick} className="block h-[450px] lg:h-[350px] shadow-2xl max-w-sm p-6 bg-white border border-gray-200 rounded-lg  hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h2 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">{title}</h2>
        <p className="font-normal text-gray-700 dark:text-gray-400">{desc}</p>
        <div className='flex justify-center py-4 bottom-2'>
        <button className='bg-bodydark py-2 px-4 rounded text-black '>Read More</button>
        </div>
    
      </Link>

      


    </div>
  );
};

export default SpeachesCard;
