import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../img/30.jpg";
import img2 from "../../img/32.jpg";
import img3 from "../../img/75.jpg";
import "./Homepage.css";

const HomeCard = () => {
  const cards = [
    {
      imageSrc: img1,
      title: "The World War -II",
      content:
        "It was Sunday 1st December 1919, early morning that A Ghani, founder of the .",
      linkTo: "/war",
    },
    {
      imageSrc: img2,
      title: "Founder of The East Bengal Regiment - Bangladesh Army ",
      content:
        "Major A Ghani was a multi-dimensional leader and a statesman who did not give in to pressure as he was above party .",
      linkTo: "/political",
    },
    {
      imageSrc: img3,
      title: "Defender of his mother tongue",
      content:
        " When the dark shadows of World War 2 were looming over the Indian Subcontinent Major Ghani was serving in the Bengal Fire Brigade in Kolkata.  .",
      linkTo: "/east",
    },
    {
      imageSrc: img3,
      title: "Architect of Nation Building",
      content:
        " When the dark shadows of World War 2 were looming over the Indian Subcontinent Major Ghani was serving in the Bengal Fire Brigade in Kolkata.  .",
      linkTo: "/east",
    },
  ];

  return (
    <div className="flex flex-wrap justify-around">
      {cards.map((card, index) => (
        <div
          key={index}
          className="bg-black text-white border w-96 text-justify border-gray-200 rounded-lg shadow-xl dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 mb-4"
        >
          <img
            className="w-full h-32  rounded-t-lg"
            src={card.imageSrc}
            alt={card.title}
          />
          <div className="p-4 flex flex-col justify-between leading-normal">
            <h5 className="mb-2 text-xl font-bold tracking-tight dark:text-white">
              {card.title}
            </h5>
            <p className="mb-3 text-sm font-normal dark:text-gray-400">
              {card.content}
            </p>
            <div className="flex justify-end">
              <Link
                to={card.linkTo}
                className="text-white border-2 border-info px-4 pb-1 pt-[4px] text-xs font-medium uppercase leading-normal bg-info transition duration-150 ease-in-out hover:bg-neutral-500 hover:bg-opacity-10 hover:text-info-600 focus:bg-info-600 focus:text-info-600 focus:outline-none focus:ring-0 active:bg-info-700 active:text-info-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeCard;
