import React, { useState } from 'react';


import img2 from "../../img/64.jpg";

import img3 from "../../img/126.jpeg";


import './media.css'


const info = [
  {
    name: img3,
    sl: 4,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
 
  {
    name: img2,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },



//   {
//     name: img6,
//     sl: 6,
//     phone: '8',
//     desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
//   },
//   {
//     name: img5,
//     sl: 6,
//     phone: '8',
//     desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
//   },

 
];

function MediaCard() {
   
  const [model, setModel] = useState(false);
  const [temp, setTemp] = useState({});

  const getModel = (i) => {
    setTemp(info[i]);
    setModel(true);
  }

  const toggleModal = () => {
    setModel(false);
  }

  return (
    <div className=' lg:h-screen h-full '>
        <div className='recog-top recog-top-text '>
            <h1 className='py-8 text-center  text-3xl font-semibold'>Media & News</h1>
        </div>
      <div className="flex flex-wrap justify-center  gap-16 mt-8 p-16">
      {info.map((inf, i) => (
          <img  key={inf.sl} className='flex flex-wrap  gap-16 w-[290px] h-[300px]'
        
           src={inf.name} onClick={() => getModel(i)} alt={inf.sl} />
        ))}
     

      </div>

      {model && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center modal-text p-8  z-50">
         
           
            <div
            onClick={toggleModal}
            className="absolute w-full h-full bg-opacity-90 bg-bodydark "
          ></div>
          
          <div className="bg-white p-8  w-11/12 min-w-1/4 max-w-xl relative rounded-[10px]">
   <div className='flex justify-end'>
   <button
             className="bg-red-500 hover:bg-red-700   font-bold py-2 px-4 rounded-full"
              onClick={toggleModal}
            >
         <span class="text-lg font-semibold">X</span>

            </button>
   </div>
            {model && (
              <div className="text-lg mt-8"></div>
            )}
            {model && (
              <img src={temp.name} alt=""  className="mt-4 h-[500px] w-full" />
            )}
           
          </div> 
            </div>
         
      
      )}
      
    </div>
  );
}

export default MediaCard;
