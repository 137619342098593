import React from 'react'

import Model from '../Modal/Modal'
import Demo from '../Modal/Demo'

import '../Modal/Modal.css'

import PhotoMenu from './PhotoMenu'

const Photo = () => {
  return (
    <div className='w-full py-16  top-0 '>
    <div className=''>
    <PhotoMenu/> 
   </div>
   <div className='pt-8'>
   <Model/>
   </div>
    
   
   <div className='pt-8'>
  <Demo/>
   </div>
    </div>
  )
}

export default Photo
