import React from 'react'

import './Timeline.css'
import bornImg from '../../img/30.jpg';
const Born = () => {
  return (
    <div className="flex xl:pt-[90px]" id="born">
      <div className="w-[50%] time-bac xl:block hidden mt-4 background-born ">

     
       





      </div>
      <div className="xl:w-[50%] time-bac w-full" >
      <div className='time-text mx-10 py-8'>
      <h1 className='xl:text-2xl text-2xl'>  <span className="border-b-4 border-green-500  pb-3 w-24 font-bold">Born</span>
</h1>


<div className='mt-16 '>
    
   <div className='mt-1'>
   
    <p className='my-4 text-justify text-lg font-home-about'>
      
    It was Sunday 1st December 1919, early morning that A Ghani, founder of a the East Bengal Regiment in the village of Nagaish, under Brmanpara Upazila in Comilla district saw the first ray of light and wailed loudly to announce his arrival. 
He was strong and survived the harsh conditions of those period and painfully lost his loving mother when he was two and a half years of age. It broke the heart of his father and did not agree with the idea of remarriage. However, seeing the condition of Ghani growing without the care of a mother he with the pressure of his relative remarried. 
Although he was his father’s only son, loved one but he was more attached to his uncle’s family just close by.  Most of his uncles were y of his age and they filled his life.  They were well established as landlords and had considerable chunk of landed property. There are many anecdotes of his childhood, but I would rather leave it here to do justice to the limited space.
The picture of the bungalow was not there when he was born. It was erected in 1946 after the WW 2 when he returned home with the arrears of his salary and bought it for approximately Rs 3000 from a police officer in Assam while returning home. It still stands the weathering of time after surviving many storms

    </p>
   </div>
    
    <div>
        <p></p>
    </div>
</div>

      
    </div>
      
      </div>
    </div>
  )
}

export default Born
