import React, { useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../images/logo/logo.svg';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const trigger = useRef(null);
  const sidebar = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current || !target) return;

      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) {
        return;
      }

      setSidebarOpen(false);
    };

    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [sidebarOpen, setSidebarOpen, sidebar]);

  return (
    <aside
    ref={sidebar}
    className={`fixed left-0 top-0 z-9999 flex h-screen w-72.5 flex-col bg-bodydark1 duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
      sidebarOpen ? 'translate-x-0' : '-translate-x-full'
    }`}
   
  >
      {/* Sidebar Header */}
      <div className="flex items-center justify-between gap-2  px-6 py-5.5 lg:py-6.5">
        <NavLink to="/">
        <div className='mx-8 bg-black text-bodydark1 h-[150px] w-[120px] flex justify-center rounded-lg items-center'>
        <div className='font-semibold'>
          <h1 className='text-xl  uppercase bg-text'>
            <p className='text-xl'>Major.A</p> Ghani
          </h1>
        </div>
      </div>
        </NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* Sidebar Header */}

                    <Link
                  to="/"
                  className=" group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 "
                  
                >
                 
                  Profile
                </Link>

                <ul className="mt-3 mx-8 side-text text-lg font-normal uppercase">
        {[
          { to: '/', label: 'Home' },
          { to: '/timeline', label: 'Timeline' },
          { to: '/books', label: 'Various Speeches' },
          { to: '/anchorW', label: 'anchor' },
          { to: '/photo', label: 'Photo archives' },
          { to: '/event', label: 'Event' },
          { to: '/media', label: 'Media & News' },
          { to: '/contact', label: 'Contact' },
        ].map((item, index) => (
          <li key={index} className="my-2 hover:text-green-500 border-b border-gray-500 pb-2">
            <NavLink to={item.to} activeClassName="text-green-500">
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
