import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Homepage from './components/Homepage/Homepage';

import Time from './components/Timeline/Time'
import Sidebar from './components/layout/Sidebar';
import Topbar from './components/layout/Topbar';
import { useState } from 'react';
import SpeecheContainer from './components/Speeach/SpeecheContainter';
import SpeachesDetails from './components/Speeach/SpeachesDetails';
import Photo from './components/Photo/Photo'
import Media from './components/Media/Media'
import Event from './components/Event/Event'
import Contact from './components/contact/Contact'


const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
  
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
    {/* <!-- ===== Page Wrapper Start ===== --> */}
    <div className="flex h-screen overflow-hidden">
      {/* <!-- ===== Sidebar Start ===== --> */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* <!-- ===== Sidebar End ===== --> */}

      {/* <!-- ===== Content Area Start ===== --> */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        {/* <!-- ===== Header Start ===== --> */}
        <Topbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Header End ===== --> */}

        {/* <!-- ===== Main Content Start ===== --> */}
        <main>
          <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="timeline" element={<Time/>} />
            <Route path="/books" element={<SpeecheContainer/>} />
            <Route path="/speeches/:sl" element={<SpeachesDetails/>} />
            <Route path="/photo" element={<Photo/>} />
            <Route path="/media" element={<Media/>} />
            <Route path="/event" element={<Event/>} />
            <Route path="/contact" element={<Contact/>} />
            
         
          </Routes>
          </div>
        </main>
        {/* <!-- ===== Main Content End ===== --> */}
       
      </div>
      {/* <!-- ===== Content Area End ===== --> */}
    </div>
    {/* <!-- ===== Page Wrapper End ===== --> */}
  </div>
   
  );
};

export default App;
