import React from 'react'
import Born from './Born'
import Education from './Education'
import EastBengal from './EastBengal'
import Political from './Political'
import Death from './Death'
import TimeMenu from './TimeMenu'
import './Timeline.css'
import Anchor from './Anchor'

const Time = () => {
  return (
    <div className=' py-16  top-0 '>
      <div className='w-full '>
      <TimeMenu/> 
     </div>
     <div className=''>
     <Born/>
     </div>
      
     
     <div className='pt-16'>
    <Education/>
     </div>
     <div className='pt-16'>
     <EastBengal/>
     </div>
     <div className='pt-16'>
     <Political/>
     </div>

     <div className='pt-16'>
     <Anchor/>
     </div>
     <div className='pt-16'>
     <Death/>
     </div>
     <button
        className="fixed bottom-8 right-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        Back to Top
      </button>
    </div>
  )
}

export default Time
