import React from 'react'
import BackgroundImageSlider from '../BackgroundImageSlider/BackgroundImageSlider'
import './Timeline.css'

const EastBengal = () => {
  return (
    <div className="flex pt-[90px] " id="east">
      <div className="w-[50%] time-bac xl:block hidden background-east">
       





      </div>
      <div className="xl:w-[50%] time-bac w-full">
      <div className='time-text mx-10 py-16'>
      <h1 className='xl:text-xl text-xl'>  <span className="border-b-4 border-green-500  pb-3 w-24 font-bold">East Bengal Regiment</span>
</h1>


<div className='mt-16 '>
    
   <div className='mt-1'>
 
    <p className='my-4 text-justify text-lg font-home-about'>
      
    When the dark shadows of World War 2 was looming over the Indian Subcontinent Major Ghani was serving in the Bengal Fire Brigade in Kolkata. He was the first Muslim Officer to join the service in 1939. I need to highlight the role of the Pioneer corps briefly for understanding. Indian Pioneer Corps may be called a franchise of Royal Pioneer Corps who fought in the European wars and were in some cases retained permanently. But in the case of Indian Subcontinent the Pioneer corps was only raised just as the threat of invasion developed from the East. So who so ever had physical fitness to work was recruited regardless of other attributes needed to make a regular fighting soldier.
They were deemed to provide labour force to build roads, bridges, airfields, bunkers etc to help the regular soldiers fight the invading fierce life defying enemies.  However, if emergency arose, they were also given weapons to defend themselves. War changes demographics the history and national boundaries. That's what was happening during that period in the Indian Subcontinent. On one side there was famine brought by the war where the British Prime minister the so-called hero but villain on this part of the world diverted most of the resources for war mongering leaving the civil population die of starvation.  The other blatant fact emerged that the two major religious groups of the Subcontinent could not live together. The fact that the Bengali Muslims lived in the delta of Ganges River collecting the filths coming from the upstream. Bangalees were not  liked by the other ethnic groups of Bihar, Punjab, Baluch and many more who called them so called martial race.
Under this back drop of looming ominous socio political changes Capt Ghani who was assigned to fight East of Brahmaputra river to Akyab saw the brutalities of war for over three years and the impending changes to shape his mind.

    </p>
   </div>
    
    <div>
        <p></p>
    </div>
</div>

      
    </div>
      
      </div>
    </div>
  )
}

export default EastBengal
