import React from 'react';
import { Link } from 'react-router-dom';
import './Contact.css';

const Contact = () => {
  return (
    <div className=' contact-bac min-h-screen h-auto '>
      <section className='w-full'>
      <div className='contact-top '>
            <h1 className='py-4 text-center modal-top-text text-3xl font-semibold uppercase'>Contact </h1>
        </div>
        <div className='flex flex-wrap  items-center pt-20 justify-center gap-16  px-4 text-justify '>
          <div className=''>
            <ul className='text-lg py-3'>
              <li className='text-xl py-2'>
                <span>Major Abdul Ghani Foundation</span>
              </li>
              <li className='py-2'>01727850240, 01711524584</li>
              <li className='py-2'>004407534761341(whats app)</li>
         
              <li className='py-2 uppercase'>20 Seacole close,blackblurl,lancashire,BB1 2RA,UK</li>
            
            </ul>
          </div>

          

          <div>
            <ul className='text-lg py-3'>
              <li className='py-4'>
                <label htmlFor='email'>Email*</label>
              </li>
              <li className='py-4'>
                <input
                  type='text'
                  className='py-3 px-7 bg-transparent border border-white focus:border-pink-500'
                />
              </li>
              <li className='py-2'>
                <button className='border border-white bg-yellow-800 text-white hover:bg-violet-700 py-2 px-5 mt-6 rounded-lg'>
                  Submit
                </button>
              </li>
            </ul>
          </div>

          <div>
            <ul className='text-white text-lg py-3'>
              <li>
                <ul className='flex gap-4 text-2xl'>
                  <li>
                    <i className='fab fa-facebook'></i>
                  </li>
                  <li>
                    <i className='fab fa-twitter'></i>
                  </li>
                  <li>
                    <i className='fab fa-linkedin'></i>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
