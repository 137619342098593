import React, { useState } from 'react';


import img1 from "../../img/1.jpg";
import img2 from "../../img/2.jpg";
import img3 from "../../img/3.jpg";
import img4 from "../../img/4.jpg";
import img5 from "../../img/5.jpg";
import img6 from "../../img/6.jpg";
import img7 from "../../img/7.jpg";
import img8 from "../../img/8.jpg";
import img9 from "../../img/9.jpg";
import img10 from "../../img/11.jpg";
import img11 from "../../img/12.jpg";
import img12 from "../../img/117.jpeg";
import img13 from "../../img/13.jpg";
import img14 from "../../img/14.jpg";
import img15 from "../../img/15.jpg";
import img16 from "../../img/16.jpg";
import img17 from "../../img/17.jpg";
import img18 from "../../img/18.jpg";
import img19 from "../../img/19.jpg";
import img20 from "../../img/20.jpg";
import img21 from "../../img/21.jpg";
import img22 from "../../img/22.jpg";
import img23 from "../../img/23.jpg";
import img24 from "../../img/24.jpg";
import img25 from "../../img/25.jpg";
import img26 from "../../img/26.jpg";
import img27 from "../../img/27.jpg";
import img28 from "../../img/28.jpg";
import img29 from "../../img/29.jpg";
import img30 from "../../img/30.jpg";
import img31 from "../../img/31.jpg";
import img32 from "../../img/32.jpg";

import img35 from "../../img/35.jpg";
import img36 from "../../img/36.jpg";
import img37 from "../../img/37.jpg";
import img38 from "../../img/38.jpg";





import img45 from "../../img/45.jpg";
import img46 from "../../img/46.jpg";

import img50 from "../../img/50.jpg";
import img51 from "../../img/51.jpg";
import img52 from "../../img/52.jpg";
import img53 from "../../img/53.jpg";
import img54 from "../../img/54.jpg";
import img55 from "../../img/55.jpg";
import img56 from "../../img/56.jpg";
import img57 from "../../img/57.jpg";
import img58 from "../../img/58.jpg";
import img59 from "../../img/59.jpg";
import img60 from "../../img/60.jpg";
import img61 from "../../img/61.jpg";
import img62 from "../../img/62.jpg";
import img63 from "../../img/63.jpg";
import img64 from "../../img/64.jpg";
import img65 from "../../img/65.jpg";
import img66 from "../../img/66.jpg";
import img67 from "../../img/67.jpg";
import img68 from "../../img/68.jpg";
import img69 from "../../img/69.jpg";
import img70 from "../../img/70.jpg";
import img71 from "../../img/71.jpg";
import img72 from "../../img/72.jpg";
import img73 from "../../img/73.jpg";
import img74 from "../../img/74.jpg";
import img75 from "../../img/75.jpg";
import img76 from "../../img/76.jpg";
import img77 from "../../img/77.jpg";
import img78 from "../../img/78.jpg";
import img79 from "../../img/79.jpg";

import img80 from "../../img/80.jpeg";
import img81 from "../../img/81.jpg";
import img82 from "../../img/82.jpg";
import img83 from "../../img/83.jpg";
import img84 from "../../img/84.jpg";

import img86 from "../../img/86.jpg";

import img88 from "../../img/88.jpg";
import img89 from "../../img/89.jpg";
import img90 from "../../img/90.jpg";
import img91 from "../../img/91.jpg";
import img92 from "../../img/92.jpg";
import img93 from "../../img/93.jpg";
import img94 from "../../img/94.jpg";
import img95 from "../../img/95.jpg";
import img96 from "../../img/96.jpg";
import img97 from "../../img/97.jpg";
import img98 from "../../img/98.jpg";
import img99 from "../../img/99.jpg";
import img100 from "../../img/100.jpg";
import img101 from "../../img/101.jpg";
import img102 from "../../img/102.jpg";
import img103 from "../../img/103.jpg";
import img104 from "../../img/104.jpg";
import img105 from "../../img/105.jpg";
import img106 from "../../img/106.jpg";
import img107 from "../../img/107.jpg";
import img108 from "../../img/108.jpg";
import img109 from "../../img/109.jpg";
import img110 from "../../img/110.jpg";
import img111 from "../../img/111.jpg";
import img112 from "../../img/112.jpg";
import img113 from "../../img/113.jpg";
import img114 from "../../img/114.jpg";
import img115 from "../../img/115.jpg";
import img116 from "../../img/116.jpg";


import './Modal.css'


const info = [

  {
    name: img14,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },

  {
    name: img15,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img16,
    sl: 3,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img17,
    sl: 4,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img18,
    sl: 5,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
    

  },
  {
    name: img19,
    sl: 6,
    phone: '8',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img20,
    sl: 8,
    phone: '9',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img21,
    sl: 10,
    phone: '57',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img22,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img23,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img24,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img35,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img26,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img27,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },

  {
    name: img28,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img29,
    sl: 3,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img30,
    sl: 4,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img31,
    sl: 5,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
    

  },
  {
    name: img32,
    sl: 6,
    phone: '8',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  
  {
    name: img35,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img36,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img37,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img38,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },

 

  
  {
    name: img45,
    sl: 6,
    phone: '8',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img46,
    sl: 8,
    phone: '9',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
 
 
  {
    name: img50,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img51,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img52,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img53,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },

  {
    name: img54,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img55,
    sl: 3,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img56,
    sl: 4,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img57,
    sl: 5,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
    

  },

  {
    name: img73,
    sl: 10,
    phone: '57',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img74,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img75,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img76,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img77,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img78,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img79,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },

  {
    name: img80,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },

  {
    name: img82,
    sl: 4,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img83,
    sl: 5,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
    

  },
  {
    name: img84,
    sl: 6,
    phone: '8',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  
  {
    name: img86,
    sl: 10,
    phone: '57',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },

  {
    name: img88,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img89,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img90,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img91,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img92,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },

  {
    name: img93,
    sl: 2,
    phone: '011',
   desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img94,
    sl: 3,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img95,
    sl: 4,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img96,
    sl: 5,
    phone: '011',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
    

  },
  {
    name: img97,
    sl: 6,
    phone: '8',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img98,
    sl: 8,
    phone: '9',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img99,
    sl: 10,
    phone: '57',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img100,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img101,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img102,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img103,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img104,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img105,
    sl: 8,
    phone: '9',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img106,
    sl: 10,
    phone: '57',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img107,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img108,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img109,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img110,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img111,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img112,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img113,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img114,
    sl: 11,
    date: '11',
  
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img115,
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  },
  {
    name: img116,
    type:"major",
    sl: 11,
    phone: '11',
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo, perferendis",
  }






];

function Demo() {

  const [model, setModel] = useState(false);
  const [temp, setTemp] = useState({});

  const getModel = (i) => {
    setTemp(info[i]);
    const temType=temp.type;
    

    console.log(temp[i]);
    setModel(true);
  }

  const toggleModal = () => {
    setModel(false);
  }

  return (
    <div className='modal-bac' id="demo">
      <h1 class="block uppercase font-sans text-3xl py-16 antialiased py-8 text-center font-semibold leading-tight tracking-normal text-blue-gray-900">
major ghani's others photo
  </h1>
      <div className="flex flex-wrap justify-center  gap-8 mt-8 lg:p-4 p-2">
      {info.map((inf, i) => (
         <img
         key={inf.sl}
         className='flex flex-wrap h-[170px] w-[150px] l'
         src={inf.name}
         onClick={() => getModel(i)}
      
      
         alt={inf.sl}
        // Specify the animation type here
       />
        ))}
     

      </div>

      {model && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center modal-text p-8  z-50">
         
           
            <div
            onClick={toggleModal}
            className="absolute w-full h-full bg-opacity-90 bg-gray-800 "
          ></div>
          
          <div className="bg-gray-100 p-8  w-11/12 min-w-1/4 max-w-xl relative rounded-[10px]">
   <div className='flex justify-end'>
   <button
             className="bg-red-500 hover:bg-red-700   font-bold py-2 px-4 rounded-full"
              onClick={toggleModal}
            >
         <span class="text-lg font-semibold">X</span>

            </button>
   </div>
            {model && (
              <div className="text-lg mt-8"></div>
            )}
            {model && (
              <img src={temp.name} alt="" className="mt-4  h-[500px] lg:w-full w-full" />
            )}
           
          </div> 
            </div>
         
      
      )}


    </div>
  );
}

export default Demo;
