import React from 'react'
import BackgroundImageSlider from '../BackgroundImageSlider/BackgroundImageSlider'
import './Timeline.css';


const Education = () => {
  return (
    <div className="flex pt-[90px] " id='education'>
      <div className="w-[50%]  time-bac xl:block background-education hidden">
       





      </div>
      <div className="xl:w-[50%] time-bac w-full">
      <div className='time-text mx-10 py-16'>
      <h1 className='xl:text-xl text-xl'>  <span className="border-b-4 border-green-500  pb-3 w-24 font-bold">Education</span>
</h1>


<div className='mt-16 '>
    
   <div className='mt-1'>

    <p className='my-4 text-justify text-lg font-home-about'>
      
    Major Abdul Ghani, born in Comilla in 1915, initially studied at a madrasah, excelling in both academics and sports. He later shifted to mainstream education under the guidance of Mr. Hamid Nomani, enrolling in Muslim High School, Chittagong. After passing his entrance exam from Khulna Zilla School at 21, Ghani attended Kolkata Islamia College, supported by Dr. Abdus Sobhan. He completed his Intermediate in Arts and enrolled in a BA program. However, his studies faced challenges after Dr. Sobhan's death in 1939 and the outbreak of World War II.
    His educational path took a significant turn when Mr. Hamid Hasan Nomani, the Magistrate of Chittagong, recognized Ghani's potential and decided to support him. Following Nomani's advice, Ghani transferred from the madrasah to Muslim High School in Chittagong to receive a more formal education. He later continued his studies at Khulna Zilla School, where he passed his entrance examination at the age of 21. Subsequently, Ghani was admitted to Kolkata Islamia College, where he was financially supported by Dr. Abdus Sobhan, his maternal uncle. At Islamia College, he completed his Intermediate in Arts and enrolled in a BA program. However, his academic pursuits faced challenges after Dr. Sobhan's untimely death in 1939 and the onset of World War II.
    </p>
   </div>
    
    <div>
        <p></p>
    </div>
</div>

      
    </div>
      
      </div>
    </div>
  )
}

export default Education
