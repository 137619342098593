import React from 'react';
import BackgroundImageSlider from '../BackgroundImageSlider/BackgroundImageSlider';
import HomeAbout from './HomeAbout';
import './Homepage.css'


const Homepage = () => {
  return (

   <div>
  
     <BackgroundImageSlider/>
   </div>
  );
};

export default Homepage;
